import React from 'react';

export const Google: React.FC = () => (
  <>
    <div className="title is-1">
      <i className="fab fa-google" />
      OAuth ホームページ
    </div>
    利用しているサービスは以下です。
    <div className="content">
      <ul>
        <li>SP ☆12参考表</li>
        <li>IIDX Score Table</li>
      </ul>
    </div>
  </>
);
