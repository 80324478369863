import React from 'react';

import { Table } from './Table';

export const Node: React.FC = () => {
  return (
    <>
      <p className="title is-3">
        <i className="fa fa-layer-group" />
        nodes
      </p>
      <p className="subtitle is-5">稼働しているnodeを閲覧できます。</p>
      <hr />
      <Table />
    </>
  );
};
