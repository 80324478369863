import ActionReducer from 'action-reducer';
import produce from 'immer';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { RpcError } from 'grpc-web';

import { RootState } from './';
import { actions as uiActions } from './UI';

import { TagResponse, GetTagRequest } from '../proto/tagmanager/tagmanager_pb';
import { TagManagerClient } from '../proto/tagmanager/TagmanagerServiceClientPb';

const apiHost =
  process.env.NODE_ENV === 'production'
    ? 'https://api.iidx.app'
    : 'http://localhost:8080';

const now = new Date().getTime();
const initialState = {
  client: new TagManagerClient(apiHost, {}, {}),
  abilitysheet: {
    tag: 'loading...',
    updatedAt: now,
  },
  iidx_score_table: {
    tag: 'loading...',
    updatedAt: now,
  },
  server: {
    tag: 'loading...',
    updatedAt: now,
  },
};
const { createAction, reducer } = ActionReducer(initialState);
export default reducer;

const FETCH_TAG = 'tagManager/fetchTag';
export const actions = {
  fetchTag: createAction(FETCH_TAG, (state, repo: string) => state),
  updateTag: createAction('tagManager/updateTag', (state, tag: TagResponse) =>
    produce(state, (draft) => {
      const repo = tag.getRepo();
      if (
        repo === 'abilitysheet' ||
        repo === 'iidx_score_table' ||
        repo === 'server'
      ) {
        draft[repo] = {
          tag: tag.getTag(),
          updatedAt: tag.getUpdatedAt(),
        };
      }
    }),
  ),
};

function getTag(client: TagManagerClient, request: GetTagRequest) {
  return new Promise<TagResponse>((resolve, reject) => {
    client.getTag(request, {}, (err, ret) => {
      if (err !== null) {
        reject(err);
      }
      resolve(ret);
    });
  });
}

function* fetchTag(action: { type: string; payload: [string] }) {
  try {
    const request = new GetTagRequest();
    const client: TagManagerClient = yield select(
      (state: RootState) => state.tagManager.client,
    );
    request.setRepo(action.payload[0]);
    const ret: TagResponse = yield call(getTag, client, request);
    yield put(actions.updateTag(ret));
  } catch (e) {
    if (e instanceof RpcError) {
      yield put(
        uiActions.notifyRequest({
          type: 'danger',
          message: `code: ${e.code}, message: ${e.message}`,
        }),
      );
    }
  }
}

export function* tagManagerSaga() {
  yield takeEvery(FETCH_TAG, fetchTag);
}
