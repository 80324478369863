import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../../ducks/TagManager';
import { RootState } from '../../ducks';

export const Table: React.FC = () => {
  const dispatch = useDispatch();
  const tagManager = useSelector((state: RootState) => state.tagManager);
  const { abilitysheet, iidx_score_table: ist, server } = tagManager;

  useEffect(() => {
    dispatch(actions.fetchTag('abilitysheet'));
    dispatch(actions.fetchTag('iidx_score_table'));
    dispatch(actions.fetchTag('server'));
  }, [dispatch]);

  return (
    <table className="table is-striped is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>
            <i className="fa fa-rocket" />
            サービス
          </th>
          <th>
            <i className="fa fa-tags" />
            タグ
          </th>
          <th>
            <i className="fa fa-clock" />
            更新日
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <a href="https://sp12.iidx.app">☆12 SP参考表</a>
          </td>
          <td>
            <span className="tag is-info">{abilitysheet.tag}</span>
          </td>
          <td>{new Date(abilitysheet.updatedAt).toLocaleString()}</td>
        </tr>
        <tr>
          <td>
            <a href="https://score.iidx.app">IIDX Score Table</a>
          </td>
          <td>
            <span className="tag is-info">{ist.tag}</span>
          </td>
          <td>{new Date(ist.updatedAt).toLocaleString()}</td>
        </tr>
        <tr>
          <td>gRPC Server</td>
          <td>
            <span className="tag is-dark">{server.tag}</span>
          </td>
          <td>{new Date(server.updatedAt).toLocaleString()}</td>
        </tr>
      </tbody>
    </table>
  );
};
