/**
 * @fileoverview gRPC-Web generated client stub for scandal
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.3.1
// 	protoc              v3.20.1
// source: proto/scandal/scandal.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as proto_scandal_scandal_pb from '../../proto/scandal/scandal_pb';


export class ScandalServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListTweets = new grpcWeb.MethodDescriptor(
    '/scandal.ScandalService/ListTweets',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    proto_scandal_scandal_pb.ListTweetsResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_scandal_scandal_pb.ListTweetsResponse.deserializeBinary
  );

  listTweets(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_scandal_scandal_pb.ListTweetsResponse>;

  listTweets(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: proto_scandal_scandal_pb.ListTweetsResponse) => void): grpcWeb.ClientReadableStream<proto_scandal_scandal_pb.ListTweetsResponse>;

  listTweets(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: proto_scandal_scandal_pb.ListTweetsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/scandal.ScandalService/ListTweets',
        request,
        metadata || {},
        this.methodDescriptorListTweets,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/scandal.ScandalService/ListTweets',
    request,
    metadata || {},
    this.methodDescriptorListTweets);
  }

  methodDescriptorCreateTweet = new grpcWeb.MethodDescriptor(
    '/scandal.ScandalService/CreateTweet',
    grpcWeb.MethodType.UNARY,
    proto_scandal_scandal_pb.CreateTweetRequest,
    google_protobuf_empty_pb.Empty,
    (request: proto_scandal_scandal_pb.CreateTweetRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  createTweet(
    request: proto_scandal_scandal_pb.CreateTweetRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  createTweet(
    request: proto_scandal_scandal_pb.CreateTweetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  createTweet(
    request: proto_scandal_scandal_pb.CreateTweetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/scandal.ScandalService/CreateTweet',
        request,
        metadata || {},
        this.methodDescriptorCreateTweet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/scandal.ScandalService/CreateTweet',
    request,
    metadata || {},
    this.methodDescriptorCreateTweet);
  }

}

