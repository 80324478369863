import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import { Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { createReduxHistoryContext } from 'redux-first-history';

import createRootReducer, { rootSaga } from './ducks';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

const sagaMiddleware = createSagaMiddleware();
let middlewares: Middleware[] = [];
if (process.env.NODE_ENV !== 'production') {
  middlewares = [createLogger()];
}

export const store = configureStore({
  reducer: createRootReducer(routerReducer),
  middleware: [routerMiddleware, sagaMiddleware, ...middlewares],
});

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);
