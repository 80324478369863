import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../../../ducks/Kube';
import { RootState } from '../../../ducks';

export const Table: React.FC = () => {
  const dispatch = useDispatch();
  const pods = useSelector((state: RootState) => state.kube.pods);

  useEffect(() => {
    dispatch(actions.fetchPods());
  }, [dispatch]);

  if (pods.length === 0) {
    return (
      <progress
        style={{ marginTop: '30px' }}
        className="progress is-info"
      ></progress>
    );
  }

  return (
    <table className="table is-striped is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>
            <i className="fa fa-rocket" />
            name
          </th>
          <th>
            <i className="fa fa-check-circle" />
            ready
          </th>
          <th>
            <i className="fa fa-server" />
            node
          </th>
          <th>
            <i className="fa fa-clock" />
            createdAt
          </th>
        </tr>
      </thead>
      <tbody>
        {pods.map((pod) => {
          return (
            <tr key={pod.getName()}>
              <td>{pod.getName()}</td>
              <td>
                <span className="tag is-info">{pod.getReady()}</span>
              </td>
              <td>{pod.getNode()}</td>
              <td>{new Date(pod.getCreatedAt() * 1000).toLocaleString()}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
