import React from 'react';

export const Terms: React.FC = () => (
  <>
    <div className="title is-1">
      <i className="fas fa-sign" />
      利用規約
    </div>
    <div className="content">
      本サービスは第三者によるアプリケーションであり、関連するサービスとは関係がありません。
      <br />
      関連サービスに被害を被る行為を行った場合、即時アカウント停止を行う可能性があります。
      <br />
      また、本サービスを利用したことによる関連サービスの被害について一切の責任を追わないこととします。
      <br />
      上記の承認できる方のみ、利用を開始してください。
    </div>
  </>
);
