import React, { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../ducks';
import { actions } from '../ducks/UI';

export const Navbar: React.FC = () => {
  const openNavbar = useSelector((state: RootState) => state.ui.openNavbar);
  const { pathname } = useLocation();
  const openNavbarClass = openNavbar ? 'is-active' : '';

  const dispatch = useDispatch();
  const onClick = useCallback(
    () => dispatch(actions.toggleNavbar()),
    [dispatch],
  );

  return (
    <nav aria-label="main navigation" className="navbar is-link">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <i className="fa fa-terminal" />
        </Link>
        <a
          aria-expanded="false"
          aria-label="menu"
          className={`navbar-burger burger ${openNavbarClass}`}
          data-target="navbar"
          role="button"
          onClick={onClick}
          href="#nav"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>
      <div className={`navbar-menu ${openNavbarClass}`} id="navbar">
        <div className="navbar-start">
          <HideNav />
        </div>
        <div className="navbar-end">
          {pathname.startsWith('/terms') ? null : (
            <a
              className="navbar-item"
              href="https://grafana.iidx.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-tv" />
              Grafana
            </a>
          )}
        </div>
      </div>
    </nav>
  );
};

const HideNav = () => {
  const auth = useSelector((state: RootState) => state.auth.auth);
  if (!auth) {
    return null;
  }

  return (
    <>
      <Link className="navbar-item" to="/tag">
        <i className="fa fa-code-branch" />
        tag
      </Link>
      <div className="navbar-item has-dropdown is-hoverable">
        <a className="navbar-link" href="#nav">
          <i className="fa fa-layer-group" />
          kube
        </a>
        <div className="navbar-dropdown">
          <Link className="navbar-item" to="/kube/pods">
            pods
          </Link>
          <Link className="navbar-item" to="/kube/nodes">
            nodes
          </Link>
        </div>
      </div>
      <Link className="navbar-item" to="/deploy">
        <i className="fa fa-rocket" />
        deploy
      </Link>
      <Link className="navbar-item" to="/scandal">
        <i className="fa fa-camera" />
        scandal
      </Link>
    </>
  );
};
