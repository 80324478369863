import { AnyAction, combineReducers, Reducer } from 'redux';
import { fork } from 'redux-saga/effects';
import { RouterState } from 'redux-first-history';

import tagManager, { tagManagerSaga } from './TagManager';
import kube, { kubeSaga } from './Kube';
import ui, { uiSaga } from './UI';
import deploy, { deploySaga } from './Deploy';
import auth, { authSaga } from './Auth';
import scandal, { scandalSaga } from './Scandal';

const rootReducer = (router: Reducer<RouterState, AnyAction>) =>
  combineReducers({
    router,
    tagManager,
    kube,
    ui,
    deploy,
    auth,
    scandal,
  });
export default rootReducer;
export type RootState = ReturnType<typeof rootReducer> extends Reducer<infer S>
  ? S
  : never;

export function* rootSaga() {
  yield fork(tagManagerSaga);
  yield fork(kubeSaga);
  yield fork(deploySaga);
  yield fork(uiSaga);
  yield fork(authSaga);
  yield fork(scandalSaga);
}
