import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../ducks';
import { actions } from '../ducks/UI';

export const Notify: React.FC = () => {
  const notify = useSelector((state: RootState) => state.ui.notify);
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(actions.closeNotify());
  }, [dispatch]);

  if (notify.message === '') {
    return null;
  }

  return (
    <div className={`notification is-${notify.type}`}>
      {notify.message}
      <button className="delete" onClick={handleClick} />
      <span />
    </div>
  );
};
