import React from 'react';

export const Top: React.FC = () => {
  return (
    <>
      <section className="hero is-dark is-bold">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">
              <i className="fa fa-terminal" />
              iidx-app
            </h1>
            <h2 className="subtitle">
              iidx.appドメインのサービスを俯瞰できます。
            </h2>
          </div>
        </div>
      </section>
    </>
  );
};
