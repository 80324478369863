import ActionReducer from 'action-reducer';
import produce from 'immer';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { RpcError } from 'grpc-web';

import { RootState } from '.';
import { actions as uiActions } from './UI';

import { ScandalServiceClient } from '../proto/scandal/ScandalServiceClientPb';
import {
  Tweet,
  ListTweetsResponse,
  CreateTweetRequest,
} from '../proto/scandal/scandal_pb';

const apiHost =
  process.env.NODE_ENV === 'production'
    ? 'https://api.iidx.app'
    : 'http://localhost:8080';

const initialState = {
  client: new ScandalServiceClient(apiHost, {}, {}),
  tweets: [] as Tweet[],
};
const { createAction, reducer } = ActionReducer(initialState);
export default reducer;

const CREATE_TWEET = 'scandal/createTweet';
const LIST_TWEETS = 'scandal/listTweets';
export const actions = {
  listTweets: createAction(LIST_TWEETS, (state) => state),
  createTweet: createAction(
    CREATE_TWEET,
    (state, id: string, text: string) => state,
  ),
  updateTweets: createAction('scandal/updateTweets', (state, tweets: Tweet[]) =>
    produce(state, (draft) => {
      draft.tweets = tweets;
    }),
  ),
};

function listTweetsResponse(client: ScandalServiceClient, request: Empty) {
  return new Promise<ListTweetsResponse>((resolve, reject) => {
    client.listTweets(request, {}, (err, ret) => {
      if (err !== null) {
        reject(err);
      }
      resolve(ret);
    });
  });
}

function* listTweets(action: { type: string; payload: [string] }) {
  try {
    const request = new Empty();
    const client: ScandalServiceClient = yield select(
      (state: RootState) => state.scandal.client,
    );
    const ret: ListTweetsResponse = yield call(
      listTweetsResponse,
      client,
      request,
    );
    yield put(actions.updateTweets(ret.getTweetList()));
  } catch (e) {
    if (e instanceof RpcError) {
      yield put(
        uiActions.notifyRequest({
          type: 'danger',
          message: `code: ${e.code}, message: ${e.message}`,
        }),
      );
    }
  }
}

function createTweetResponse(
  client: ScandalServiceClient,
  request: CreateTweetRequest,
) {
  return new Promise<Empty>((resolve, reject) => {
    client.createTweet(request, {}, (err, ret) => {
      if (err !== null) {
        reject(err);
      }
      resolve(ret);
    });
  });
}

function* createTweet(action: { type: string; payload: [string, string] }) {
  try {
    const request = new CreateTweetRequest();
    request.setId(action.payload[0]);
    request.setText(action.payload[1]);
    const client: ScandalServiceClient = yield select(
      (state: RootState) => state.scandal.client,
    );
    yield call(createTweetResponse, client, request);
    // eslint-disable-next-line no-restricted-globals
    scrollTo(0, 0);
    yield put(
      uiActions.notifyRequest({
        type: 'info',
        message: '共有しました',
      }),
    );
  } catch (e) {
    if (e instanceof RpcError) {
      yield put(
        uiActions.notifyRequest({
          type: 'danger',
          message: `code: ${e.code}, message: ${e.message}`,
        }),
      );
    }
  }
}

export function* scandalSaga() {
  yield takeEvery(LIST_TWEETS, listTweets);
  yield takeEvery(CREATE_TWEET, createTweet);
}
