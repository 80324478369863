import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../../ducks/Scandal';
import { RootState } from '../../ducks';

export const Scandal: React.FC = () => {
  const tweets = useSelector((state: RootState) => state.scandal.tweets);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.listTweets());
  }, [dispatch]);

  const handleClick = useCallback(
    (id: string, text: string) => () => {
      dispatch(actions.createTweet(id, text));
    },
    [dispatch],
  );

  const handleChange = useCallback(
    (id: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(
        actions.updateTweets(
          tweets.map((tweet) => {
            if (tweet.getId() === id) {
              tweet.setText(e.target.value);
            }
            return tweet;
          }),
        ),
      );
    },
    [dispatch, tweets],
  );

  return (
    <>
      <p className="title is-3">
        <i className="fa fa-camera" />
        Scandal
      </p>
      <p className="subtitle is-5">SNSの共有状態を管理します。</p>
      <table className="table">
        <thead>
          <tr>
            <th />
            <th>Text</th>
            <th>Created At</th>
            <th>Image or Movie</th>
          </tr>
        </thead>
        <tbody>
          {tweets.map((tweet) => (
            <tr key={tweet.getId()}>
              <td>
                <button
                  className="button is-info"
                  onClick={handleClick(tweet.getId(), tweet.getText())}
                >
                  ReCap
                </button>
              </td>
              <td>
                <textarea
                  className="textarea"
                  rows={5}
                  cols={140}
                  value={tweet.getText()}
                  onChange={handleChange(tweet.getId())}
                />
              </td>
              <td>{tweet.getCreatedAt()}</td>
              <td>
                {tweet.getMovieUrl() ? (
                  <video
                    controls={true}
                    style={{ maxWidth: '50%' }}
                    src={tweet.getMovieUrl()}
                  />
                ) : (
                  <img
                    style={{ maxWidth: '50%' }}
                    alt="スクリーンショット"
                    src={tweet.getImageUrl()}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
