import ActionReducer from 'action-reducer';
import produce from 'immer';
import { takeEvery, put, delay } from 'redux-saga/effects';

const initialState = {
  openNavbar: false,
  notify: {
    type: 'info',
    message: '',
  },
};
const { createAction, reducer } = ActionReducer(initialState);
export default reducer;

const NOTIFY_REQUEST = 'ui/notifyRequest';
interface NotifyPayload {
  type: 'info' | 'danger';
  message: string;
}
export const actions = {
  toggleNavbar: createAction('ui/toggleNavbar', (state) =>
    produce(state, (draft) => {
      draft.openNavbar = !draft.openNavbar;
    }),
  ),
  notifyRequest: createAction(
    NOTIFY_REQUEST,
    (state, payload: NotifyPayload) => state,
  ),
  notify: createAction('ui/notify', (state, payload: NotifyPayload) =>
    produce(state, (draft) => {
      draft.notify = payload;
    }),
  ),
  closeNotify: createAction('ui/closeNotify', (state) =>
    produce(state, (draft) => {
      draft.notify.message = '';
    }),
  ),
};

function* notify(action: { type: string; payload: [NotifyPayload] }) {
  try {
    yield put(actions.notify(action.payload[0]));
    yield delay(3000);
    yield put(actions.closeNotify());
  } catch (e) {
    throw e;
  }
}

export function* uiSaga() {
  yield takeEvery(NOTIFY_REQUEST, notify);
}
