import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from './Table';
import { RootState } from '../../../ducks/index';
import { actions } from '../../../ducks/Kube';

export const Pod: React.FC = () => {
  const dispatch = useDispatch();
  const namespace = useSelector((state: RootState) => state.kube.namespace);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(actions.updateNamespace(e.target.value)),
    [dispatch],
  );

  const onBlur = useCallback(() => dispatch(actions.fetchPods()), [dispatch]);

  return (
    <>
      <p className="title is-3">
        <i className="fa fa-layer-group" />
        pods
      </p>
      <p className="subtitle is-5">稼働しているpodを閲覧できます。</p>
      <div className="columns">
        <div className="column is-one-fifth">
          <label className="label">namespace</label>
          <input
            className="input"
            value={namespace}
            onChange={onChange}
            onBlur={onBlur}
          />
        </div>
      </div>
      <hr />
      <Table />
    </>
  );
};
