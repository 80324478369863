import ActionReducer from 'action-reducer';
import produce from 'immer';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { RpcError } from 'grpc-web';

import { RootState } from '.';
import { actions as uiActions } from './UI';

import { DeployServiceClient } from '../proto/deploy/DeployServiceClientPb';
import {
  Chart,
  DeployResponse,
  DeployRequest,
  ChartResponse,
} from '../proto/deploy/deploy_pb';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { TagManagerClient } from '../proto/tagmanager/TagmanagerServiceClientPb';
import { TagsRequest, TagsResponse } from '../proto/tagmanager/tagmanager_pb';

const apiHost =
  process.env.NODE_ENV === 'production'
    ? 'https://api.iidx.app'
    : 'http://localhost:8080';

const initialState: {
  client: DeployServiceClient;
  tagsClient: TagManagerClient;
  charts: Chart[];
  chart: string;
  tags: string[];
  tag: string;
  status: string;
} = {
  client: new DeployServiceClient(apiHost, {}, {}),
  tagsClient: new TagManagerClient(apiHost, {}, {}),
  charts: [],
  chart: '',
  tags: [],
  tag: '',
  status: 'none',
};
const { createAction, reducer } = ActionReducer(initialState);
export default reducer;

const DEPLOY_REQUEST = 'deploy/deployRequest';
const CHARTS_REQUEST = 'deploy/chartsRequest';
const TAGS_REQUEST = 'deploy/tagsRequest';
export const actions = {
  deployRequest: createAction(DEPLOY_REQUEST, (state) => state),
  deploy: createAction('deploy/deploy', (state, resp: DeployResponse) =>
    produce(state, (draft) => {
      draft.status = resp.getStatus();
      draft.chart = '';
      draft.tag = '';
    }),
  ),
  updateChart: createAction('deploy/updateChart', (state, payload: string) =>
    produce(state, (draft) => {
      draft.chart = payload;
    }),
  ),
  updateTag: createAction('deploy/updateTag', (state, payload: string) =>
    produce(state, (draft) => {
      draft.tag = payload;
    }),
  ),
  chartsRequest: createAction(CHARTS_REQUEST, (state) => state),
  chart: createAction('deploy/chart', (state, resp: ChartResponse) =>
    produce(state, (draft) => {
      draft.charts = resp.getChartList();
    }),
  ),
  tagsRequest: createAction(TAGS_REQUEST, (state) => state),
  tags: createAction('deploy/tags', (state, resp: TagsResponse) =>
    produce(state, (draft) => {
      draft.tags = resp.getTagsList();
      if (draft.tags.length > 0) {
        draft.tag = draft.tags[0];
      }
    }),
  ),
};

function deploy(client: DeployServiceClient, request: DeployRequest) {
  return new Promise<DeployResponse>((resolve, reject) => {
    client.deploy(request, {}, (err, ret) => {
      if (err !== null) {
        reject(err);
      }
      resolve(ret);
    });
  });
}

function* deployRequest(action: { type: string; payload: [string] }) {
  try {
    const request = new DeployRequest();
    const client: DeployServiceClient = yield select(
      (state: RootState) => state.deploy.client,
    );
    const chart: string = yield select(
      (state: RootState) => state.deploy.chart,
    );
    const tag: string = yield select((state: RootState) => state.deploy.tag);
    request.setChart(chart);
    request.setTag(tag);
    const ret: DeployResponse = yield call(deploy, client, request);
    yield put(actions.deploy(ret));
  } catch (e) {
    if (e instanceof RpcError) {
      yield put(
        uiActions.notifyRequest({
          type: 'danger',
          message: `code: ${e.code}, message: ${e.message}`,
        }),
      );
    }
  }
}

function charts(client: DeployServiceClient, request: Empty) {
  return new Promise<ChartResponse>((resolve, reject) => {
    client.searchChart(request, {}, (err, ret) => {
      if (err !== null) {
        reject(err);
      }
      resolve(ret);
    });
  });
}

function* chartsRequest(action: { type: string; payload: [string] }) {
  try {
    const request = new Empty();
    const client: DeployServiceClient = yield select(
      (state: RootState) => state.deploy.client,
    );
    const ret: ChartResponse = yield call(charts, client, request);
    yield put(actions.chart(ret));
  } catch (e) {
    if (e instanceof RpcError) {
      yield put(
        uiActions.notifyRequest({
          type: 'danger',
          message: `code: ${e.code}, message: ${e.message}`,
        }),
      );
    }
  }
}

function tags(client: TagManagerClient, request: TagsRequest) {
  return new Promise<TagsResponse>((resolve, reject) => {
    client.getTags(request, {}, (err, ret) => {
      if (err !== null) {
        reject(err);
      }
      resolve(ret);
    });
  });
}

function* tagsRequest(action: { type: string; payload: [string] }) {
  try {
    const request = new TagsRequest();
    const client: TagManagerClient = yield select(
      (state: RootState) => state.deploy.tagsClient,
    );
    const chart: string = yield select(
      (state: RootState) => state.deploy.chart,
    );
    request.setRepo(chart);
    const ret: TagsResponse = yield call(tags, client, request);
    yield put(actions.tags(ret));
  } catch (e) {
    if (e instanceof RpcError) {
      yield put(
        uiActions.notifyRequest({
          type: 'danger',
          message: `code: ${e.code}, message: ${e.message}`,
        }),
      );
    }
  }
}

export function* deploySaga() {
  yield takeEvery(DEPLOY_REQUEST, deployRequest);
  yield takeEvery(CHARTS_REQUEST, chartsRequest);
  yield takeEvery(TAGS_REQUEST, tagsRequest);
}
