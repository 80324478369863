import ActionReducer from 'action-reducer';
import produce from 'immer';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { RpcError } from 'grpc-web';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';

import { RootState } from '.';
import { actions as uiActions } from './UI';

import { AuthServiceClient } from '../proto/auth/AuthServiceClientPb';

const apiHost =
  process.env.NODE_ENV === 'production'
    ? 'https://api.iidx.app'
    : 'http://localhost:8080';

const initialState: {
  client: AuthServiceClient;
  auth: boolean;
} = {
  client: new AuthServiceClient(apiHost, {}, {}),
  auth: false,
};
const { createAction, reducer } = ActionReducer(initialState);
export default reducer;

const EMPTY_REQUEST = 'auth/emptyRequest';
export const actions = {
  emptyRequest: createAction(EMPTY_REQUEST, (state) => state),
  updateAuth: createAction('auth/updateAuth', (state, auth: boolean) =>
    produce(state, (draft) => {
      draft.auth = auth;
    }),
  ),
};

function empty(client: AuthServiceClient, request: Empty) {
  return new Promise<Empty>((resolve, reject) => {
    client.empty(request, {}, (err, ret) => {
      if (err !== null) {
        reject(err);
      }
      resolve(ret);
    });
  });
}

function* emptyRequest(action: { type: string; payload: [string] }) {
  try {
    const request = new Empty();
    const client: AuthServiceClient = yield select(
      (state: RootState) => state.auth.client,
    );
    yield call(empty, client, request);
    yield put(actions.updateAuth(true));
  } catch (e) {
    if (e instanceof RpcError) {
      yield put(
        uiActions.notifyRequest({
          type: 'info',
          message: `code: ${e.code}, message: ${e.message}`,
        }),
      );
    }
  }
}

export function* authSaga() {
  yield takeEvery(EMPTY_REQUEST, emptyRequest);
}
