import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import 'bulma/css/bulma.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { store, history } from './store';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <Provider {...{ store }}>
    <Router {...{ history }}>
      <App />
    </Router>
  </Provider>,
);

serviceWorker.unregister();
