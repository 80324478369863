/**
 * @fileoverview gRPC-Web generated client stub for deploy
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.3.1
// 	protoc              v3.20.1
// source: proto/deploy/deploy.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as proto_deploy_deploy_pb from '../../proto/deploy/deploy_pb';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';


export class DeployServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorDeploy = new grpcWeb.MethodDescriptor(
    '/deploy.DeployService/Deploy',
    grpcWeb.MethodType.UNARY,
    proto_deploy_deploy_pb.DeployRequest,
    proto_deploy_deploy_pb.DeployResponse,
    (request: proto_deploy_deploy_pb.DeployRequest) => {
      return request.serializeBinary();
    },
    proto_deploy_deploy_pb.DeployResponse.deserializeBinary
  );

  deploy(
    request: proto_deploy_deploy_pb.DeployRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_deploy_deploy_pb.DeployResponse>;

  deploy(
    request: proto_deploy_deploy_pb.DeployRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: proto_deploy_deploy_pb.DeployResponse) => void): grpcWeb.ClientReadableStream<proto_deploy_deploy_pb.DeployResponse>;

  deploy(
    request: proto_deploy_deploy_pb.DeployRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: proto_deploy_deploy_pb.DeployResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deploy.DeployService/Deploy',
        request,
        metadata || {},
        this.methodDescriptorDeploy,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deploy.DeployService/Deploy',
    request,
    metadata || {},
    this.methodDescriptorDeploy);
  }

  methodDescriptorSearchChart = new grpcWeb.MethodDescriptor(
    '/deploy.DeployService/SearchChart',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    proto_deploy_deploy_pb.ChartResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    proto_deploy_deploy_pb.ChartResponse.deserializeBinary
  );

  searchChart(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<proto_deploy_deploy_pb.ChartResponse>;

  searchChart(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: proto_deploy_deploy_pb.ChartResponse) => void): grpcWeb.ClientReadableStream<proto_deploy_deploy_pb.ChartResponse>;

  searchChart(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: proto_deploy_deploy_pb.ChartResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/deploy.DeployService/SearchChart',
        request,
        metadata || {},
        this.methodDescriptorSearchChart,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/deploy.DeployService/SearchChart',
    request,
    metadata || {},
    this.methodDescriptorSearchChart);
  }

}

