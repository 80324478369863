import ActionReducer from 'action-reducer';
import produce from 'immer';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import { RpcError } from 'grpc-web';

import { RootState } from '.';
import { actions as uiActions } from './UI';

import { KubeClient } from '../proto/kube/KubeServiceClientPb';
import {
  PodsResponse,
  GetPodRequest,
  Pod,
  Node,
  NodesResponse,
} from '../proto/kube/kube_pb';

const apiHost =
  process.env.NODE_ENV === 'production'
    ? 'https://api.iidx.app'
    : 'http://localhost:8080';

const initialState: {
  client: KubeClient;
  pods: Pod[];
  nodes: Node[];
  namespace: string;
} = {
  client: new KubeClient(apiHost, {}, {}),
  pods: [],
  nodes: [],
  namespace: 'abilitysheet',
};
const { createAction, reducer } = ActionReducer(initialState);
export default reducer;

const FETCH_PODS = 'kube/fetchPods';
const FETCH_NODES = 'kube/fetchNodes';
export const actions = {
  fetchPods: createAction(FETCH_PODS, (state) =>
    produce(state, (draft) => {
      draft.pods = [];
    }),
  ),
  updatePods: createAction('kube/updatePods', (state, resp: PodsResponse) =>
    produce(state, (draft) => {
      draft.pods = resp.getPodsList();
    }),
  ),
  updateNamespace: createAction(
    'kube/updateNamespace',
    (state, namespace: string) =>
      produce(state, (draft) => {
        draft.namespace = namespace;
      }),
  ),
  fetchNodes: createAction(FETCH_NODES, (state) =>
    produce(state, (draft) => {
      draft.nodes = [];
    }),
  ),
  updateNodes: createAction('kube/updateNodes', (state, resp: NodesResponse) =>
    produce(state, (draft) => {
      draft.nodes = resp.getNodesList();
    }),
  ),
};

function getPods(client: KubeClient, request: GetPodRequest) {
  return new Promise<PodsResponse>((resolve, reject) => {
    client.getPods(request, {}, (err, ret) => {
      if (err !== null) {
        reject(err);
      }
      resolve(ret);
    });
  });
}

function* fetchPods(action: { type: string; payload: [string] }) {
  try {
    const request = new GetPodRequest();
    const client: KubeClient = yield select(
      (state: RootState) => state.kube.client,
    );
    const namespace: string = yield select(
      (state: RootState) => state.kube.namespace,
    );
    request.setNamespace(namespace);
    const ret: PodsResponse = yield call(getPods, client, request);
    yield put(actions.updatePods(ret));
  } catch (e) {
    if (e instanceof RpcError) {
      yield put(
        uiActions.notifyRequest({
          type: 'danger',
          message: `code: ${e.code}, message: ${e.message}`,
        }),
      );
    }
  }
}

function getNodes(client: KubeClient, request: Empty) {
  return new Promise<NodesResponse>((resolve, reject) => {
    client.getNodes(request, {}, (err, ret) => {
      if (err !== null) {
        reject(err);
      }
      resolve(ret);
    });
  });
}

function* fetchNodes(action: { type: string; payload: [string] }) {
  try {
    const request = new Empty();
    const client: KubeClient = yield select(
      (state: RootState) => state.kube.client,
    );
    const ret: NodesResponse = yield call(getNodes, client, request);
    yield put(actions.updateNodes(ret));
  } catch (e) {
    if (e instanceof RpcError) {
      yield put(
        uiActions.notifyRequest({
          type: 'danger',
          message: `code: ${e.code}, message: ${e.message}`,
        }),
      );
    }
  }
}

export function* kubeSaga() {
  yield takeEvery(FETCH_PODS, fetchPods);
  yield takeEvery(FETCH_NODES, fetchNodes);
}
