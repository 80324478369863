import React from 'react';

export const PrivacyPolicy: React.FC = () => (
  <>
    <div className="title is-1">
      <i className="fas fa-signature" />
      プライバシーポリシー
    </div>
    <div className="content">
      本サービスで収集される個人情報はユーザの利便性向上の目的で利用されます。
      <ul>
        <li>メールアドレス</li>
        <ul>
          <li>パスワードを忘れたときの再発行通知先として利用します</li>
        </ul>
        <li>OAuth情報</li>
        <ul>
          <li>ログインの簡易化のために利用します</li>
          <li>連携先の機能を利用したサービスの一部として利用します</li>
        </ul>
        <li>Cookie</li>
        <ul>
          <li>ログイン情報の保持に利用します</li>
        </ul>
      </ul>
    </div>
  </>
);
