/**
 * @fileoverview gRPC-Web generated client stub for tagmanager
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.3.1
// 	protoc              v3.20.1
// source: proto/tagmanager/tagmanager.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as proto_tagmanager_tagmanager_pb from '../../proto/tagmanager/tagmanager_pb';


export class TagManagerClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetTag = new grpcWeb.MethodDescriptor(
    '/tagmanager.TagManager/GetTag',
    grpcWeb.MethodType.UNARY,
    proto_tagmanager_tagmanager_pb.GetTagRequest,
    proto_tagmanager_tagmanager_pb.TagResponse,
    (request: proto_tagmanager_tagmanager_pb.GetTagRequest) => {
      return request.serializeBinary();
    },
    proto_tagmanager_tagmanager_pb.TagResponse.deserializeBinary
  );

  getTag(
    request: proto_tagmanager_tagmanager_pb.GetTagRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_tagmanager_tagmanager_pb.TagResponse>;

  getTag(
    request: proto_tagmanager_tagmanager_pb.GetTagRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: proto_tagmanager_tagmanager_pb.TagResponse) => void): grpcWeb.ClientReadableStream<proto_tagmanager_tagmanager_pb.TagResponse>;

  getTag(
    request: proto_tagmanager_tagmanager_pb.GetTagRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: proto_tagmanager_tagmanager_pb.TagResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tagmanager.TagManager/GetTag',
        request,
        metadata || {},
        this.methodDescriptorGetTag,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tagmanager.TagManager/GetTag',
    request,
    metadata || {},
    this.methodDescriptorGetTag);
  }

  methodDescriptorGetTags = new grpcWeb.MethodDescriptor(
    '/tagmanager.TagManager/GetTags',
    grpcWeb.MethodType.UNARY,
    proto_tagmanager_tagmanager_pb.TagsRequest,
    proto_tagmanager_tagmanager_pb.TagsResponse,
    (request: proto_tagmanager_tagmanager_pb.TagsRequest) => {
      return request.serializeBinary();
    },
    proto_tagmanager_tagmanager_pb.TagsResponse.deserializeBinary
  );

  getTags(
    request: proto_tagmanager_tagmanager_pb.TagsRequest,
    metadata: grpcWeb.Metadata | null): Promise<proto_tagmanager_tagmanager_pb.TagsResponse>;

  getTags(
    request: proto_tagmanager_tagmanager_pb.TagsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: proto_tagmanager_tagmanager_pb.TagsResponse) => void): grpcWeb.ClientReadableStream<proto_tagmanager_tagmanager_pb.TagsResponse>;

  getTags(
    request: proto_tagmanager_tagmanager_pb.TagsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: proto_tagmanager_tagmanager_pb.TagsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tagmanager.TagManager/GetTags',
        request,
        metadata || {},
        this.methodDescriptorGetTags,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tagmanager.TagManager/GetTags',
    request,
    metadata || {},
    this.methodDescriptorGetTags);
  }

}

