import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Routing } from './Routing';
import { actions } from './ducks/Auth';

import './App.css';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.startsWith('/terms')) return;
    dispatch(actions.emptyRequest());
  }, [dispatch, pathname]);

  return (
    <>
      <Routing />
    </>
  );
};

export default App;
