import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Navbar } from './components/Navbar';
import { NotFound } from './components/NotFound';
import { Notify } from './components/Notify';

import { Top } from './components/Top';
import { Tag } from './components/Tag';
import { Pod } from './components/Kube/Pod';
import { Node } from './components/Kube/Node';
import { Deploy } from './components/Deploy';
import { Google } from './components/Terms/Google';
import { PrivacyPolicy } from './components/Terms/PrivacyPolicy';
import { Terms } from './components/Terms';
import { Scandal } from './components/Scandal';

export const Routing: React.FC = () => (
  <>
    <Navbar />
    <section className="section">
      <div className="container">
        <Notify />
        <Routes>
          <Route path="/" element={<Top />} />
          <Route path="/tag" element={<Tag />} />
          <Route path="/kube/pods" element={<Pod />} />
          <Route path="/kube/nodes" element={<Node />} />
          <Route path="/deploy" element={<Deploy />} />
          <Route path="/scandal" element={<Scandal />} />
          <Route path="/terms/google" element={<Google />} />
          <Route path="/terms/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </section>
  </>
);
