import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../ducks';
import { actions } from '../../ducks/Deploy';

export const Deploy: React.FC = () => {
  const tag = useSelector((state: RootState) => state.deploy.tag);
  const tags = useSelector((state: RootState) => state.deploy.tags);
  const status = useSelector((state: RootState) => state.deploy.status);
  const charts = useSelector((state: RootState) => state.deploy.charts);
  const chart = useSelector((state: RootState) => state.deploy.chart);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.chartsRequest());
  }, [dispatch]);

  const handleChangeChart = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      dispatch(actions.updateChart(e.target.value));
      dispatch(actions.tagsRequest());
    },
    [dispatch],
  );
  const handleChangeTag = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) =>
      dispatch(actions.updateTag(e.target.value)),
    [dispatch],
  );
  const handleClick = useCallback(
    () => dispatch(actions.deployRequest()),
    [dispatch],
  );

  return (
    <>
      <p className="title is-3">
        <i className="fa fa-rocket" />
        deploy
      </p>
      <p className="subtitle is-5">指定されたchartとtagでデプロイします。</p>
      <div className="columns">
        <div className="column">
          <label className="label">
            <i className="fa fa-chart-bar" />
            chart
          </label>
          <div className="select">
            <select onChange={handleChangeChart} value={chart}>
              <option />
              {charts.map((chart) => (
                <option key={chart.getName()}>{chart.getName()}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="column">
          <label className="label">
            <i className="fa fa-tags" />
            tag
          </label>
          <div className="select">
            <select onChange={handleChangeTag} value={tag}>
              {tags.map((tag) => (
                <option key={tag}>{tag}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <button
        className="button is-info"
        onClick={handleClick}
        disabled={chart === '' || tag === ''}
      >
        <i className="fa fa-rocket" />
        Deploy
      </button>
      <hr />
      status: {status}
    </>
  );
};
