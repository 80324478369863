import React from 'react';

import { Table } from './Table';

export const Tag: React.FC = () => {
  return (
    <>
      <p className="title is-3">
        <i className="fa fa-code-branch" />
        タグ情報
      </p>
      <p className="subtitle is-5">
        現在デプロイされているタグと更新日を閲覧できます。
      </p>
      <Table />
    </>
  );
};
