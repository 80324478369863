import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../../../ducks/Kube';
import { RootState } from '../../../ducks';

export const Table: React.FC = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state: RootState) => state.kube.nodes);

  useEffect(() => {
    dispatch(actions.fetchNodes());
  }, [dispatch]);

  if (nodes.length === 0) {
    return (
      <progress
        style={{ marginTop: '30px' }}
        className="progress is-info"
      ></progress>
    );
  }

  return (
    <table className="table is-striped is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>
            <i className="fa fa-rocket" />
            name
          </th>
          <th>
            <i className="fa fa-check-circle" />
            ready
          </th>
          <th>
            <i className="fa fa-clock" />
            createdAt
          </th>
        </tr>
      </thead>
      <tbody>
        {nodes.map((node) => {
          return (
            <tr key={node.getName()}>
              <td>{node.getName()}</td>
              <td>
                <span
                  className={`tag is-${node.getReady() ? 'info' : 'danger'}`}
                >
                  {node.getReady() ? 'true' : 'false'}
                </span>
              </td>
              <td>{new Date(node.getCreatedAt() * 1000).toLocaleString()}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
